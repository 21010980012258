import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Location from '../components/Location'

const FindUsPage = () => (
  <Layout>
    <SEO title="Find Us" />
    <h1>Find Us</h1>
    <p>
      We're located in South Tyler in the community of Gresham, TX. We are 4
      miles south of Fresh by Brookshires and a half mile south of Loop 49 on
      Old Jacksonville Hwy, just past the bend in the road. This is a small but
      tight knit community with its own take on East Texas culture. I'm proud to
      offer quality handcrafted espresso to an area already rife with hidden
      culinary treasures. Come on over, we can't wait to serve you!
    </p>
    <Location />
  </Layout>
)

export default FindUsPage
