import styled from '@emotion/styled'
import { queries } from '../../theme/globalStyles'

export const LocationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--xl);
  margin-top: var(--xl);

  ${queries[2]} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--xxl);
  }
`

export const LocationDetails = styled.div`
  h2 {
    margin: 0 0 var(--md);
  }
  h3 {
    margin-bottom: var(--md);
  }
  ul {
    margin: 0 0 var(--xxl);
    list-style: none;
  }
`
