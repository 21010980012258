import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import * as S from './styles'

const Location = () => {
  const data = useStaticQuery(graphql`
    query {
      locationImage: file(relativePath: { eq: "location.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.LocationWrapper>
      <a
        href="https://www.google.com/maps/place/Big+Shot+Coffee+House/@32.2368666,-95.3495366,18z/data=!4m5!3m4!1s0x86483548192e4a31:0x625b625d03b538ed!8m2!3d32.2368258!4d-95.3486568"
        target="_blank"
        rel="noreferrer"
      >
        <Img
          fluid={data.locationImage.childImageSharp.fluid}
          alt="Big Shot Coffee House Location"
        />
      </a>
      <S.LocationDetails>
        <h2>Location</h2>
        <ul>
          <li>Big Shot Coffee House</li>
          <li>16700 FM 2493 #1600</li>
          <li>Tyler, Texas 75703</li>
        </ul>
        <h3>Contact</h3>
        <ul>
          <li>
            Phone:{' '}
            <a href="tel:903-630-2006" target="_blank" rel="noreferrer">
              903.630.2006
            </a>
          </li>
          <li>
            Email:{' '}
            <a
              href="mailto:info@bigshotcoffeehouse.com"
              target="_blank"
              rel="noreferrer"
            >
              info@bigshotcoffeehouse.com
            </a>
          </li>
        </ul>
      </S.LocationDetails>
    </S.LocationWrapper>
  )
}

export default Location
